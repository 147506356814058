import React from 'react';
import { BsTwitter, BsGithub, BsDownload } from 'react-icons/bs';
import { FaLinkedinIn } from 'react-icons/fa';
import { images } from '../constants';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a to="route" rel="noopener noreferrer" target="_blank" href={images.Resume} download title="Download Resume">
        <BsDownload />
      </a>

    </div>
    <div>
      <a to="route" rel="noopener noreferrer" target="_blank" href="https://twitter.com/IshanTechy">
        <BsTwitter />
      </a>

    </div>
    <div>
      <a to="route" rel="noopener noreferrer" target="_blank" href="https://linkedin.com/in/ishan-techy">
        <FaLinkedinIn />
      </a>

    </div>
    <div>
      <a to="route" rel="noopener noreferrer" target="_blank" href="https://github.com/ishan-techy">
        <BsGithub />
      </a>

    </div>
  </div>
);

export default SocialMedia;
